<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-flex xs12 lg9 md12 class="align-center px-1 pb-0 pt-3">
                        <v-sheet class="dense-inputs">
                            <v-row no-gutters>
                                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="mt-2 mb-0 force-text-left"
                                        prepend-inner-icon="mdi-filter-outline"
                                        @change="searchTerm = $event"
                                    ></v-text-field>
                                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="openCreateZohoReportDialog()" class="ml-3">
                                                <v-icon small dark v-on="on">ti ti-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.new') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-sheet>
                        <v-overlay
                            :value="loading.reports"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :footer-props="{
                                itemsPerPageOptions: [20,50,-1],
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                            :headers="headers"
                            :items="allZohoReports"
                            :items-per-page="-1"
                            :search="searchTerm"
                            :options.sync="tableOptions"
                            calculate-widths
                            class="mt-0 appic-table-light specification-table"
                            dense
                            fixed-header
                            id="zohoReportsTable"
                            item-key="AppicSetting.id"
                        >
                            <template v-slot:item.ZohoReport.title="{ item }">
                                <span class="font-weight-bold text-no-wrap">{{ item.ZohoReport.title }}</span>
                            </template>
                            <template v-slot:item.ZohoReport.filter_field="{ item }">
                                <span v-if="item.ZohoReport.filter_field == 1">{{ $t('message.yes') }}</span>
                                <span v-else>{{ $t('message.no') }}</span>
                            </template>
                            <template v-slot:item.ZohoReport.embed_link="{ item }">
                                <pre><code>{{ item.ZohoReport.embed_link }}</code></pre>
                            </template>
                            <template v-slot:item.ZohoReport.id="{ item }">
                                <div class="text-center">
                                    <v-menu>
                                        <template v-slot:activator="{ on: menu }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                        <v-icon>more_vert</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('message.moreActions') }}</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list dense>
                                            <v-list-item class="font-sm" @click="openEditZohoReportDialog(item.ZohoReport.id, item.ZohoReport.title)">
                                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateReport') }}
                                            </v-list-item>
                                            <v-list-item class="font-sm" @click="deleteZohoReport(item.ZohoReport.id, item.ZohoReport.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteReport') }}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                        </v-data-table>
                    </v-flex>
                </app-card>
            </v-layout>
        </v-container>
        <AddZohoReport
            :zoho-report-id="selectedZohoReportId"
            :zoho-report-name="selectedZohoReportTitle"
            :dialog.sync="editDialog"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="zohoReportUpdated"
            @create-done="zohoReportCreated"
        ></AddZohoReport>
    </div>
</template>

<script>
import {api} from "Api";
const AddZohoReport = () => import("Components/Appic/AddZohoReport");

export default {
    name: "ZohoReports",
    components: {AddZohoReport},
    data() {
        return {
            allZohoReports: [],
            editDialog: false,
            loader: false,
            loading: {
                save: false,
                reports: false
            },
            selectedZohoReportId: null,
            selectedZohoReportTitle: null,
            searchTerm: null,
            tableOptions: {
                page: -1
            },
            updateMode: false,
            validForm: true
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'ZohoReport.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.title'),
                    value: 'ZohoReport.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.tabName'),
                    value: 'ZohoReport.tab_title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.embeddedCode'),
                    value: 'ZohoReport.embed_link',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.hasFilter'),
                    value: 'ZohoReport.filter_field',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        async deleteZohoReport(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteZohoReport') + ' ' +  title, this.$t('message.confirmations.continueZohoReportCancelAction'), {color: 'orange'})){
                api
                    .delete('/admin/zoho-reports/' + val)
                    .then((response) => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.zohoReportDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getAllZohoReports()
                        } else {
                            this.$toast.error(this.$t('message.errors.zohoReportNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.zohoReportNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false
        },
        getAllZohoReports() {
            this.loading.settings = true
            api
                .get('/admin/zoho-reports')
                .then((response) => {
                    this.allZohoReports = response.data.data
                    this.loading.reports = false
                })
                .catch(() => {
                    this.loading.reports = false
                })
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        openCreateZohoReportDialog () {
            this.selectedZohoReportId = null
            this.selectedZohoReportTitle = null
            this.updateMode = false
            this.editDialog = true
        },
        openEditZohoReportDialog (zohoReportId, zohoReportTitle) {
            this.selectedZohoReportId = zohoReportId
            this.selectedZohoReportTitle = zohoReportTitle
            this.updateMode = true
            this.editDialog = true
        },
        zohoReportCreated () {
            this.editDialog = false;
            this.getAllZohoReports()
        },
        zohoReportUpdated () {
            this.editDialog = false;
            this.getAllZohoReports()
        },
    },
    created() {
        if(this.allZohoReports.length == 0) this.getAllZohoReports()
    },
    mounted () {
        this.$title =  this.$t('message.titles.zohoReports')
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
code {
    font-family: monospace;
}
</style>